




































































































































import { Component, Inject, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import Project from '@/shared/modules/project/models/project.model'
import { CompanyRoutes } from '@/shared/router/company'
import Can from '@/shared/components/Can.vue'
import permissions from '@/shared/helpers/permissions.helper'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import { RoleLevel } from '@/shared/modules/role/role.model'
import can from '@/shared/helpers/can.helper'
import toggleablePermissions from '@/shared/helpers/toggleable-permissions.helper'
import { getVersion } from '@/shared/helpers/version.helper'
import calculateBalanceColor from '@/shared/helpers/calculate-balance-color'

@Component({
  components: { Can },
  methods: {
    __,
    can,
    getVersion,
  }
})
export default class Index extends Vue {
  @Inject('project') getProject!: () => Project|null
  permissions = permissions
  toggleablePermissions = toggleablePermissions

  goToEdit(): void {
    this.$router.push({ name: CompanyRoutes.projectsEdit })
  }

  hasPermission(): boolean {
    if (this.user.role.level === RoleLevel.leader) return true

    return can([toggleablePermissions.projects.budget])
  }

  get project(): Project | null {
    return this.getProject()
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get budgetColor(): string {
    return calculateBalanceColor(this.project?.budget);
  }
}
