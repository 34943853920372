import ProjectBudget from "@/shared/modules/project/models/project-budget.model";

const calculateBalanceColor = (budget?: ProjectBudget) => {
  if (!budget) return 'black';

  if (
    (!budget.agreedPrice && !budget.estimatedHours) ||
    (!budget.balance.hours && !budget.balance.budget)
  ) return 'black'

  if (
    (budget.estimatedHours && (budget.balance.hours / budget.estimatedHours <= 0.1)) ||
    (budget.agreedPrice && (budget.balance.budget / budget.agreedPrice <= 0.1))
  ) return 'red';

  if (
    (budget.estimatedHours && (budget.balance.hours / budget.estimatedHours <= 0.3)) ||
    (budget.agreedPrice && (budget.balance.budget / budget.agreedPrice <= 0.3))
  ) return 'orange';

  return 'black';
}

export default calculateBalanceColor;
